<div class="w-full h-full select-none" [ngClass]="{'selected': Selected && isHeldDown }">
  
  <div
    class="flex flex-row px-2"
  >
    <!-- Icon -->
    <div class="pr-4 flex-none">

      <ng-container *ngIf="isPDF(FileName); else nonPdfFile">
        <mat-icon class="mat-xlarge-icons red-color" svgIcon="pdf"></mat-icon>
      </ng-container>

      <ng-template #nonPdfFile> 
        <ng-container *ngIf="isPhotoFile(FileName) && File != null; else noPhotoFile">
          <img class="w-[60px] h-[60px]" [src]="File" alt="File">
        </ng-container>
      </ng-template>
    
      
      <ng-template #noPhotoFile> 
        <ng-container *ngIf="isPhotoFile(FileName); else noFile">
          <mat-icon class="mat-xlarge-icons blue" svgIcon="photo"></mat-icon>
        </ng-container>
      </ng-template>

      <ng-template #noFile> 
        <mat-icon class="mat-xlarge-icons blue" svgIcon="reports"></mat-icon>
      </ng-template>

    </div>

    <!-- Title, description, etc.  -->
    <div class="min-w-[20px] flex-grow flex-1 w-52 flex-row flex items-center gap-4" tabindex="0"
    (mousedown)="onMouseDown()" (mouseup)="onMouseUp()" (click)="isHeldDown ? selectedMode() : documentSelected()">
      <p
        class="mat-subtitle-1 doc-item-details-items m-0"
      >
        {{ FileName }}
      </p>
      <p class="mat-subheading-xs font-medium">
        {{isS3Document? "S3 " : ""}}{{ DocumentTypeID | DocumentTypeIDDesc }}
      </p>
      <p class="mat-subheading-xs font-medium">{{ formattedDate }}</p> 
    </div>

    <!-- cloud indicator -->
    <div class="flex-none items-center" fxLayout="row" fxLayoutAlign="end start" style="height: 65px">
      <div class="flex-none w-5 send-btn" *ngIf="isSendable == 1">
          <mat-icon class="mat-medium-icons" svgIcon="sendable"></mat-icon>
        </div>
      <div>
      <mat-icon
        *ngIf="isDownloaded; else notDownloaded"
        class="mat-small-icons"
        >cloud</mat-icon
      >

      <ng-template #notDownloaded>
        <mat-icon class="mat-small-icons cloud-colour">cloud</mat-icon>
      </ng-template>
  
    </div>
    <div>
      <button mat-icon-button [matMenuTriggerFor]="menu" class="w-6 h-6 text-2xl ml-1.5 p-0" aria-label="Example icon-button with a menu">
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <button *ngIf="allowDelete" (click)="deleteUploadedDoc()" class="" mat-menu-item>
          <span class="mat-subtitle-1">DELETE</span>
        </button>
        <button (click)="markedSendable()" mat-menu-item >
          <span class="mat-subtitle-1">SENDABLE</span>
        </button>
        <button *ngIf="isS3Document" (click)="editCaption()" mat-menu-item >
          <span class="mat-subtitle-1">EDIT CAPTION</span>
        </button>
      </mat-menu>
    </div>
  </div>
  
  </div>
</div>
<hr class="hr-colour" style="width: 100%; margin: 0px" />
