import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy, OnInit, effect, signal } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormControl, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { SearchableDropdownComponent } from '../../inputs/searchable-dropdown/searchable-dropdown.component';
import { MatRadioModule } from '@angular/material/radio';
import { CompetersInputComponent } from '../competers-input/competers-input.component';
import { Subject } from 'rxjs';
import { isEqual } from 'lodash-es';

@Component({
  selector: 'app-competers-radio-select',
  standalone: true,
  imports: [ReactiveFormsModule, CommonModule, MatRadioModule, SearchableDropdownComponent, CompetersInputComponent],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: CompetersRadioSelectComponent,
    },
  ],
  template: `
    <div>
      <mat-radio-group [value]="chosenValue$$()" (change)="onRadioChange($event)">
        <div class="radio-options" *ngFor="let option of config.radioOptions; let i = index">
          <mat-radio-button [value]="option.name" class="text- font-rajdhani font-semibold uppercase">
            <div class="hover:cursor-pointer">{{ option.label }}</div>
          </mat-radio-button>

          <!-- Sub-option handling -->
          <ng-container>
            <div class="relative w-[195px] h-9 pl-11" *ngIf="option.type === 'select'">
              <app-searchable-dropdown
                class="w-full"
                [fitParentContainer]="true"
                [multiple]="!!option.options['multiple']"
                [formControl]="formControl"
                [options]="option.dataSource ?? []"
                (ngModelChange)="onSubOptionChange($event, option.name)"> 
              </app-searchable-dropdown>
            </div>

            <div class="relative w-[195px] h-9 pl-11" *ngIf="(['text','number'].includes(option.type))">
              <app-competers-input
                [fitParentContainer]="true"
                [formControl]="formControl"
                [type]="option.type"
                [placeholder]="option.placeholder ?? ''"
                (ngModelChange)="onSubOptionChange($event, option.name)"> 
              </app-competers-input>
            </div>
          </ng-container>
        </div>
      </mat-radio-group>
    </div>
  `,
  styles: [
    `
      .radio-options {
        margin-bottom: 15px;
      }
    `,
  ],
})
export class CompetersRadioSelectComponent implements OnDestroy, ControlValueAccessor, OnInit {
  @Input() config: any;
  @Input() formControl: FormControl;

  private destroy$ = new Subject<void>();
  protected chosenValue$$ = signal<string>(''); // Store the selected radio option name
  protected subOptionValue$$ = signal<any>(null); // Store the sub-option value
  protected val: any = null; // Store the overall value to be passed back

  constructor(private fb: FormBuilder) {
    // Reactively update the onChange handler when either chosenValue$$ or subOptionValue$$ changes

    effect(() => {
      if (this.onChange !== undefined) {
        const newValue = {
          radioOption: this.chosenValue$$(),
          subOption: this.subOptionValue$$()
        };
        this.onChange(newValue);
      }
    });

    this.writeValue(null);
  }

  ngOnInit() {
    this.chosenValue$$.set(this.config.placeholder || null); 
    this.subOptionValue$$.set(null);
  }

  writeValue(data: any) { // Accept an object with radioOption and subOption
    if (!isEqual(this.val, data)) {
      this.chosenValue$$.set(data?.radioOption ?? null);
      this.subOptionValue$$.set(data?.subOption ?? null);
    }
  }

  onChange: (data: any) => void = () => { }; // Accept an object with radioOption and subOption

  onTouched: () => void = () => { };

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onRadioChange(event: any) {
    // this.subOptionValue$$.set(null);
    const selectedValue = event.value;
    if (selectedValue) this.chosenValue$$.set(selectedValue);
  }

  onSubOptionChange(event: any, radioOptionName: string) {
    if (this.chosenValue$$() !== radioOptionName) {
      this.chosenValue$$.set(radioOptionName);
    }
    this.subOptionValue$$.set(event); // Update subOptionValue when sub-option changes
  }
}