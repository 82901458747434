import {inject, Injectable, NgZone} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {TicketActionsService} from '../../shared/services/ticket-actions/ticket-actions.service';
import {TicketSearchService} from '../../shared/services/ticket-search/ticket-search.service';
import {SnackbarService} from '../../modules/shared/snackbar/snackbar.service';
import {SnackbarType} from '../../modules/shared/snackbar/snackbar/snackbar';
import {
  TicketModalComponent,
  TicketModalComponentArgs,
  TicketModalComponentOpenFromEnum,
} from 'src/app/modules/shared/ticket/modals/ticket-modal/ticket-modal.component';
import {BehaviorSubject, distinctUntilChanged} from 'rxjs';
import {isEqual} from 'lodash-es';
import {ProgressBarService} from 'src/app/modules/shared/progress-bar/progress-bar.service';
import {api, apiKeys} from 'src/app/ENDPOINTS';
import {UtilocateApiService} from 'src/app/modules/core/api/utilocateapi.service';
import {UtilocateApiRequest} from 'src/app/modules/core/api/baseapi.service';

@Injectable({
  providedIn: 'root',
})
export class HomeWorkspaceService {
  // services
  private dialog = inject(MatDialog);
  private zone = inject(NgZone);
  private snackBarService = inject(SnackbarService);
  private ticketSearchService = inject(TicketSearchService);
  private ticketActionsService = inject(TicketActionsService);
  // observables
  private _ticketSelection$ = new BehaviorSubject<Array<number>>([]);
  // members
  private dialogRef: any;

  constructor(
    private utilocateAPIService: UtilocateApiService,
    private progressBarService: ProgressBarService,
    private ngZone: NgZone
  ) { }

  reassignTicket(info: [assignmentID: number, locatorID: number]) {
    this.zone.run(() => {
      this.ticketActionsService
        .reassignTicketToUser([info[0]], info[1])
        .then(([{Response: res, Error: e}]) => {
          if (res && res.toLowerCase() === 'success') {
            this.ticketSearchService.refreshSearchResults();
            this.snackBarService.openSnackbar('Ticket reassigned successfully', SnackbarType.success);
          } else if (e) {
            this.snackBarService.openSnackbar('Error reassigning ticket: ' + '"' + e + '"', SnackbarType.error);
          } else {
            this.snackBarService.openSnackbar('Error reassigning ticket: Unknown', SnackbarType.error);
          }
        })
        .catch(() => this.snackBarService.openSnackbar('Error reassigning ticket', SnackbarType.error));
    });
  }

  openTicket(ticketDetails: {AssignmentID: number, PrimaryID?: number}) {
    const data: TicketModalComponentArgs = {
      AssignmentID: ticketDetails.AssignmentID,
      PrimaryID: ticketDetails.PrimaryID,
      OpenedFrom: TicketModalComponentOpenFromEnum.Home,
    };

    this.ngZone.run(() => {
      if (this.dialogRef && this.dialogRef.componentInstance) {
        this.dialogRef.close();
      }

      this.dialogRef = this.dialog.open(TicketModalComponent, {
        width: '750px', // Set the fixed width
        maxWidth: '750px', // Ensure the modal does not exceed 750px in width
        height: 'calc(100% - 76px)', // Set height to occupy the full viewport height
        position: {right: '0px', bottom: '0px'}, // Position it to slide in from the right
        backdropClass: 'modal-backdrop', // Custom class for the backdrop
        panelClass: ['modal-panel', 'shadow-sm', 'slide-in-right'], // Use custom class for sliding effect
        data: data,
        disableClose: true, // Disables closing the modal by clicking on the backdrop or pressing Escape
        hasBackdrop: false
      });

      this.dialogRef.afterClosed().subscribe(() => {
        this.ticketSearchService.refreshSearchResults();
      });
    });
  }


  triggerAction({primaryIDs, actionID, assignmentIDs}: {primaryIDs: number[]; actionID: number; assignmentIDs: number[]}) {
    console.log("triggerAction");
    this.ticketActionsService
      .dispatchBulkAction(actionID, primaryIDs, assignmentIDs)
      .then((res) => {
       let failedLsps = this.ticketActionsService.failedLsps()
        if (res !== null) {          
          if (failedLsps) {
            this.ticketSelection = failedLsps;
          }
          else {
            this.ticketSearchService.refreshSearchResults();            
            this._ticketSelection$.next([]);
          }
       
        }
      })
      .catch((e) => {
        console.error(e);
      });
  }

  get ticketSelection$() {
    return this._ticketSelection$.pipe(
      distinctUntilChanged((a, b) => isEqual(a, b)),
    );
  }

  get ticketSelection(): Array<number> {
    return this._ticketSelection$.value;
  }

  set ticketSelection(value: Array<number>) {
    this._ticketSelection$.next(value);
  }

  async queryLocators() {
    this.progressBarService.start();
    let result = null;
    try {
      const apiKey = apiKeys.u2.queryLocators;
      const url = apiKeys.u2[apiKey];
      const type = api[url].type;

      const utilocateApiRequest: UtilocateApiRequest = {
        API_KEY: apiKey,
        API_TYPE: type,
        API_BODY: null,
      };

      const apiResult = await this.utilocateAPIService.invokeUtilocateApi(
        utilocateApiRequest
      );
      if (apiResult.body) {
        result = apiResult.body;
      }
    } catch (error) {
      console.error(error);
    }
    this.progressBarService.stop();
    return result;
  }
}
