/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Subject, Subscription } from "rxjs";
import { DocumentService, DocumentTypeID } from "src/app/modules/core/services/document/document.service";
import { FAMItem } from "../../fab/floating-action-menu/floating-action-menu.component";
import { TicketTagBuilderService } from "../../ticket-tags/ticket-list-tags/ticket-tags-builder.service";
import { CameraModalComponent } from "../modals/camera-modal/camera-modal.component";
import {
  CameraGalleryModalComponent,
  GalleryModelViews,
} from "../modals/gallery-modal/gallery-modal.component";
import { DocumentListItem } from "./ticket-documents-item/ticket-documents-item.component";
import { TicketService } from "../ticket.service";
import { UserService } from "src/app/modules/core/services/user/user.service";
import { SettingID } from "src/app/modules/core/services/user/setting";
import { first, switchMap, takeUntil } from "rxjs/operators";
import { ConfirmationModalComponent } from "../modals/generic-confirmation-modal/confirmation-modal.component";
import { StorageCapacityService } from "../../../../shared/services/storage/capacity/storage-capacity.service";
import { ProgressBarService } from "../../progress-bar/progress-bar.service";
import { DownloadDocumentService } from "src/app/modules/core/services/document/download-document.service";
import { SnackbarService } from "../../snackbar/snackbar.service";
import { EmailerService } from "src/app/modules/core/services/emailer/emailer.service";
import { SnackbarType } from "../../snackbar/snackbar/snackbar";
import { EmailModalComponent } from "../modals/email-modal/email-modal.component";
import { FormControl } from "@angular/forms";
import { TicketDocumentsService } from "./ticket-documents.service";
import { DocumentTypePickerModalComponent } from "../modals/document-type-picker-modal/document-type-picker-modal.component";

@Component({
  selector: 'app-ticket-documents',
  templateUrl: 'ticket-documents.component.html',
  styleUrls: ['./ticket-documents.component.scss'],
})
export class TicketDocumentsComponent implements OnInit, OnChanges {
  @Input() DocumentsList: DocumentListItem[];
  @Input() AssignmentID: string;
  @Input() PrimaryID: string;
  @Input() ticket: any;
  @Input() ExpectedDocHashDifference: any;
  @Output() documentSelect = new EventEmitter<any>();
  @Output() documentUpload = new EventEmitter<any>();
  @Output() documentDelete = new EventEmitter<any>();
  @Output() markSendable = new EventEmitter<any>();
  @Output() captionChange = new EventEmitter<any>();


  @Output() Swipe = new EventEmitter<any>();

  @ViewChild("fileInputInternal") fileInputInternal: ElementRef;
  @ViewChild("fileInputAux") fileInputAux: ElementRef;

  ExpectedDocHashTag = null;
  documentMismatchCount = 0;
  documentMismatchCountString = "";

  protected tabs = [
    { index: 0, title: 'list', icon: 'list' },
    { index: 1, title: 'gallery', icon: 'photo' },
  ];

  email: any;
  ticketEmail: any;

  fabMenu: FAMItem[];
  bottomMenuOpen = false;

  photoCounter = 0;
  PHOTO_LIMIT = 50;
  STORAGE_LIMIT = 75;
  CallTypeID: any;
  selection: any = [0];
  selected = new FormControl(0);
  unzippedFiles: any
  isHeldDown: boolean = false;
  selectedDocRows: any = []
  mergedS3Docs: any
  allowDelete: any
  deletableDocs: any

  private heldDownSubscription: Subscription;
  private docsSubject: Subscription;
  private destroy$: Subject<void> = new Subject<void>();


  constructor(
    public dialog: MatDialog,
    private tagBuilderService: TicketTagBuilderService,
    private documentService: DocumentService,
    private ticketService: TicketService,
    private ticketDocumentService: TicketDocumentsService,
    private userService: UserService,
    private storageCapacityService: StorageCapacityService,
    private progressBarService: ProgressBarService,
    private downloadDocService: DownloadDocumentService,
    private snackBarService: SnackbarService,
    private emailService: EmailerService,
  ) {
    this.email = this.ticket?.email;
  }

  onDocumentSelect(event) {
    this.documentSelect.emit(event);
  }

  async onDocumentDelete(event) {
    await this.documentDelete.emit(event);
  }

  OnMarkSendable(events) {
    this.ticketDocumentService.markSendabale(events.DocumentID, events.isS3Document)
    this.markSendable.emit(events)
  }

  selectedRows(event) {
    if (event.Removed) {
      const existingObjIndex = this.selectedDocRows.findIndex((item) => item.DocumentID === event.DocumentID && item.isS3Document === event.isS3Document);
      this.selectedDocRows.splice(existingObjIndex, 1);
    }
    else {
      this.selectedDocRows.push(event)
    }
    if (this.selectedDocRows.length <= 0) {
      this.clearSelected()
      this.ticketDocumentService.setIsHeldDown(false)
    }
  }

  onCaptionChangeEvent($event) {
    this.captionChange.emit($event);
    this.ticketDocumentService.updateCaption($event.AssignmentID, $event.DocumentID, $event.isS3Document, $event.Caption)
    .then((result) => {
      if (result) {
        this.snackBarService.openSnackbar(`Updated caption`, SnackbarType.success);
      } else {
          this.snackBarService.openSnackbar(`Failed to update caption`, SnackbarType.error);
      }
    });
  }

  clearSelected() {
    this.ticketDocumentService.clearSelected()
    this.selectedDocRows = []
  }

  /**
   * Check if user can delete the current selection 
   * @returns {boolean} can delete selection 
   */
  userCanDeleteSelection(): boolean {
    if (this.allowDelete) {
      const docsAllowedDeleteArr = this.deletableDocs.split(',');
      for (const row of this.selectedDocRows) {
        const allowed = docsAllowedDeleteArr.includes(row.DocumentTypeID.toString());
        if (!allowed) {
          return false;
        }
      }
    }
    return true;
  }

  async multiDelete() {
    const length = this.selectedDocRows.length
    let docsAllowed = []
    if (this.allowDelete) {
      docsAllowed = this.deletableDocs.split(',')
    }
    const modalData = {
      header: "Delete Document",
      message: "Are you sure you want to delete " + length + " documents",
    };


    this.dialog
      .open(ConfirmationModalComponent, {
        width: "380px",
        data: modalData,
      })
      .afterClosed()
      .subscribe(async (nextVal) => {
        if (nextVal) {
          this.ticketDocumentService.setIsHeldDown(false)
          this.snackBarService.openSnackbar(`Deleting ${this.selectedDocRows.length} documents`, SnackbarType.info);
          const cantDeleteRows = [];
          for (const row of this.selectedDocRows) {
            const allowed = docsAllowed.includes(row.DocumentTypeID.toString())
            if (allowed) {
              //don't show the message 
              await this.onDocumentDelete({ FileName: row.FileName, DocumentID: row.DocumentID, isS3Document: row.isS3Document, isDownloaded: row.isDownloaded, bFieldAdded: row.bFieldAdded, showSnackbar: false });
              await new Promise(resolve => setTimeout(resolve, 200));
            } else {
              cantDeleteRows.push(row.FileName);
            }
          }
          if (cantDeleteRows.length > 0) {
            this.snackBarService.openSnackbar(`You do not have sufficient settings to delete ${cantDeleteRows.join(', ')}`, SnackbarType.warning);
          }
          this.clearSelected();
        }
      });
  }

  async onMultiMarkSendableClick() {
    await this.multiMarkSendable();
  }


  async multiMarkSendable() {
    this.ticketDocumentService.setIsHeldDown(false);

    for (const row of this.selectedDocRows) {

      this.ticketDocumentService.markSendabale(row.DocumentID, row.isS3Document)
      await this.markSendable.emit({
        FileName: row.FileName,
        DocumentID: row.DocumentID,
        isS3Document: row.isS3Document,
        isSendable: row.isSendable
      });

      await new Promise(resolve => setTimeout(resolve, 100));
    }

    this.clearSelected();
  }

  ngOnInit(): void {
    this.fabMenu = [
    ];
 // eslint-disable-next-line no-constant-condition
    if (this.userService.isSettingActive(SettingID.ADD_DOC)) { 
      this.fabMenu.push({
        label: "Add Document",
        icon: "internal_document",
        action: this.addInternalFileClicked,
        iconType: 'svg'
      })
    }
    if (this.userService.isSettingActive(SettingID.TAKE_PHOTO)) {
      this.fabMenu.push({
        label: 'Take Photo',
        icon: 'camera',
        iconType: 'svg',
        action: this.openTakePhoto,
      });
    }
    // eslint-disable-next-line no-constant-condition
    if (this.userService.isSettingActive(SettingID.DOWNLOAD_ALL_DOCS)) { 
      this.fabMenu.push({
        label: "Download All",
        icon: "download",
        iconType: "svg",
        action: this.downloadAllClicked,
      })
    }

    if (this.userService.isSettingActive(SettingID.SHOW_TAKE_AUX_PHOTO_BUTTON)) {
      this.fabMenu.push({
        label: "Add Aux Document",
        icon: "office_document",
        action: this.addAuxFileClicked,
        iconType: 'svg'
      })
    }

    if (this.userService.isSettingActive(SettingID.ENABLES_SLS_EMAIL_SYSTEM) && this.userService.isSettingActive(SettingID.EMAIL_DOCS)) {
      this.fabMenu.push({
        label: "Email",
        icon: "email",
        action: this.emailClicked,
        iconType: 'svg'
      });
    }

    if (
      this.userService.isSettingActive(SettingID.SHOW_TAKE_AUX_PHOTO_BUTTON)
    ) {
      this.fabMenu.push({
        label: 'Take Auxiliary Photo',
        icon: 'aux_cam',
        action: this.takeAuxPhotoClicked,
        iconType: 'svg'
      });
    }
    this.allowDelete = this.userService.isSettingActive(SettingID.DELETE_DOCUMENTS)
    this.deletableDocs = this.userService.getSettingValue(SettingID.DELETE_DOCUMENTS)

    this.heldDownSubscription = this.ticketDocumentService.isHeldDown$.pipe(takeUntil(this.destroy$)).subscribe(isHeldDown => {
      this.isHeldDown = isHeldDown;
    });
    this.docsSubject = this.ticketDocumentService.docRows$.pipe(takeUntil(this.destroy$)).subscribe(docRows => {
      this.DocumentsList = docRows;
    });
  
  }

  emailClicked = () => {
    const dialogRef = this.dialog.open(EmailModalComponent, {
      width: "600px",
      height: "400px",
      data: { email: this.email },
    });
    dialogRef.afterClosed().subscribe((result) => {
      console.log("The dialog was closed");
      if (result) {
        result.value == null ||  result.value.length === 0
          ? (this.email = this.ticket.email)
          : (this.email = result.value);

        const emailObj = {
          emailObjects: [
            {
              PrimaryID: this.PrimaryID,
              AssignmentID: this.AssignmentID,
              email: this.email,
              CallTypeID: this.ticket.CallTypeID,
              RegionID: 0,
              RequestNumber: this.ticket.RequestNumber,
              Priority: 0,
              FormatID: 1,
            },
          ],
        };
        this.emailService.sendEmail(emailObj);
        this.snackBarService.openSnackbar(
          "Email sent successfully",
          SnackbarType.success,
        );
      }
    });
  }


  ngOnChanges(): void {
    if (
      this.ExpectedDocHashDifference != null &&
      this.ExpectedDocHashDifference
    ) {
      const message = this.ExpectedDocHashDifference + " documents on server";
      this.ExpectedDocHashTag =
        this.tagBuilderService.createCustomTicketTags(message);
    }
  }

  openFile = (event: any) => {

    const dialogRef = this.dialog.open(DocumentTypePickerModalComponent, {
      width: '380px',
      data: { docTypes: ["SENDABLE", "INTERNAL"] },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
       let documentTypeID
       let isSendable
       if(result.result == 'INTERNAL'){
        documentTypeID = 4
        isSendable = false
       }
       if(result.result == 'SENDABLE'){
        documentTypeID = 4
        isSendable = true
       }

       if(result.result == 'OFFICE'){
        documentTypeID = 10
        isSendable = true
       }
    const fileList: FileList | null = event.target.files;
    if (fileList && fileList.length > 0) {
      const file = fileList[0];
      let fileSize = 0;
      for (let i = 0; i < fileList.length; i++) {
        fileSize = fileSize + fileList[i].size;
      }

      if (this.checkStorage(fileSize)) {
        this.ticketDocumentService.disableSync(false)
        this.handleFileInput(fileList, documentTypeID, isSendable);
      }
    }
      }
    });
  
  }
  

  checkStorage(fileSize) {
    let currentValue;
    this.storageCapacityService.totalRemaining$.subscribe({
      next: (value) => {
        currentValue = value;
      },
    });
    currentValue = currentValue * 0.7;

    if (fileSize < currentValue) {
      return true;
    } else {
      const modalData = {
        header: "Limit Reached",
        message: "To many files chosen try again",
      };
      this.dialog
        .open(ConfirmationModalComponent, {
          width: '380px',
          data: modalData,
        })
        .afterClosed()
        .subscribe(() => {
          return false;
        });
    }
  }

  handleFileInput(event, documentTypeID, isSendable?) {
    const files = [];
    this.ticketService.insertTicketChangedToIDB(this.AssignmentID);
    for (let i = 0; i < event.length; i++) {
      const file = event[i];
      files.push({ file: file, documentTypeID, isSendable });
    }
    this.documentUpload.emit(files);
  }

  openTakePhoto = () => {
    this.storageCapacityService.updateComplete$
      .pipe(
        switchMap(() => this.storageCapacityService.totalUsedPercent$),
        first()
      )
      .subscribe((val: number) => {
        if (val < this.STORAGE_LIMIT) {
          this.ticketService.insertTicketChangedToIDB(this.AssignmentID);
          const newPhotoSubject = new Subject<any>();
          const photosTakenObjects = [];
          const rawPhotosTakenObjects = [];

          newPhotoSubject.asObservable().subscribe((image) => {
            rawPhotosTakenObjects.push(image);
          });

          this.dialog
            .open(CameraModalComponent, {
              disableClose: true,
              maxWidth: '100vw',
              maxHeight: '100vh',
              height: '100%',
              width: '100%',
              data: {
                newPhotoSubject,
                photoLimit: this.PHOTO_LIMIT,
              },
              panelClass: 'nothing-modal',
            })
            .afterClosed()
            .subscribe(() => {
              if (rawPhotosTakenObjects.length > 0) {
                this.dialog
                  .open(CameraGalleryModalComponent, {
                    disableClose: true,
                    maxWidth: '100vw',
                    maxHeight: '100vh',
                    height: '100%',
                    width: '100%',
                    data: {
                      images: rawPhotosTakenObjects,
                    },
                    panelClass: 'nothing-modal',
                  })
                  .afterClosed()
                  .subscribe((imagesToSave) => {
                    const result = imagesToSave.result;
                    for (let i = 0; i < result.length; i++) {
                      const filename = this.createImageName();
                      
                      const f = this.dataURLtoFile(result[i].data.src, filename);
                      photosTakenObjects.push({
                        caption: result[i].caption,
                        file: f,
                        documentTypeID: DocumentTypeID.OldDocument,
                      });
                    }
                    this.documentUpload.emit(photosTakenObjects);
                  });
              } else {
                this.documentUpload.emit(photosTakenObjects);
              }
            });
        } else {
          this.storageCapacityService.storageModal();
        }
      });
    this.storageCapacityService.update$.next();
  };

  private createImageName() {
    this.photoCounter++;
    return (
      '' +
      this.AssignmentID +
      ' - ' +
      this.documentService.formatDateForFileName(new Date()) +
      this.photoCounter +
      '.jpg'
    );
  }

  takeAuxPhotoClicked = () => {
    this.storageCapacityService.updateComplete$
      .pipe(
        switchMap(() => this.storageCapacityService.totalUsedPercent$),
        first()
      )
      .subscribe((val) => {
        if (val < this.STORAGE_LIMIT) {
          this.ticketService.insertTicketChangedToIDB(this.AssignmentID);
          const newPhotoSubject = new Subject<any>();
          const photosTakenObjects = [];
          const rawPhotosTakenObjects = [];

          newPhotoSubject.asObservable().subscribe((image) => {
            rawPhotosTakenObjects.push(image);
          });

          this.dialog
            .open(CameraModalComponent, {
              disableClose: true,
              maxWidth: '100vw',
              maxHeight: '100vh',
              height: '100%',
              width: '100%',
              data: {
                newPhotoSubject,
                photoLimit: 1,
              },
              panelClass: 'nothing-modal',
            })
            .afterClosed()
            .subscribe(() => {
              if (rawPhotosTakenObjects.length > 0) {
                this.dialog
                  .open(CameraGalleryModalComponent, {
                    disableClose: true,
                    maxWidth: '100vw',
                    maxHeight: '100vh',
                    height: '100%',
                    width: '100%',
                    data: {
                      images: rawPhotosTakenObjects,
                      galleryView: GalleryModelViews.FULL,
                    },
                    panelClass: 'nothing-modal',
                  })
                  .afterClosed()
                  .subscribe((imagesToSave) => {
                    const result = imagesToSave.result;
                    for (let i = 0; i < result.length; i++) {
                      const filename = this.createImageName();
                      const f = this.dataURLtoFile(result[i].data.src, filename);
                      photosTakenObjects.push({
                        caption: result[i].caption,
                        file: f,
                        documentTypeID: DocumentTypeID["Auxiliary Image"],
                      });
                    }
                    this.documentUpload.emit(photosTakenObjects);
                  });
              } else {
                this.documentUpload.emit(photosTakenObjects);
              }
            });
        } else {
          this.storageCapacityService.storageModal();
        }
      });
    this.storageCapacityService.update$.next();
  };

  openAuxDocument = (event: any) => {
    let documentTypeID = 4;

    const fileList: FileList | null = event.target.files;
    if (fileList && fileList.length > 0) {
      const file = fileList[0];
      let fileSize = 0;
      for (let i = 0; i < fileList.length; i++) {
        fileSize = fileSize + fileList[i].size;
      }

      if (this.checkStorage(fileSize)) {
        this.ticketService.handleAuxDocument(fileList[0]);
      }
    }
  }

  addInternalFileClicked = () => {
    this.fileInputInternal.nativeElement.click();
  };

  addAuxFileClicked = () => {
    this.fileInputAux.nativeElement.click();
  };

  /**
   */
  // openOfficeDocument = () => {
  //   const documentTypeID = DocumentTypeID["Office Internal"];
  //   const acceptedFileTypes = [".pdf", ".txt", ".eml", ".jpg", ".jpeg", ".png"];
  //   const fileInput = document.createElement("input");
  //   fileInput.accept = acceptedFileTypes.join(",");
  //   fileInput.multiple = true;
  //   fileInput.type = "file";
  //   fileInput.addEventListener("change", (event) => {
  //     const fileList = (event.target as HTMLInputElement).files;

  //     if (fileList && fileList.length > 0) {
  //       let fileSize = 0;
  //       for (let i = 0; i < fileList.length; i++) {
  //         fileSize = fileSize + fileList[i].size;
  //       }
  //       if (this.checkStorage(fileSize)) {
  //         this.handleFileInput(fileList, documentTypeID, true);
  //       }
  //     }
  //   });

  //   fileInput.click();
  // }

  // https://stackoverflow.com/questions/6850276/how-to-convert-dataurl-to-file-object-in-javascript
  private dataURLtoFile(dataUrl, fileName) {
    let arr = dataUrl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], fileName, { type: mime });
  }

  onSwipe(event) {
    this.Swipe.emit(event);
  }

  //called when you click "add office document"
  // addOfficeFileClicked = () => {
  //   this.storageCapacityService.updateComplete$
  //     .pipe(
  //       switchMap(() => this.storageCapacityService.totalUsedPercent$),
  //       first(),
  //     )
  //     .subscribe((val) => {
  //       if (val < 75) {
  //         this.openOfficeDocument();
  //       } else {
  //         this.storageCapacityService.storageModal();
  //       }
  //     });
  //   this.storageCapacityService.update$.next();
  // }

  downloadAllClicked = async () => {
    try {

      // prevent single downloads when user is selecting for multi download
      this.progressBarService.start();

      // download all
      const result = await this.downloadDocService.getDocumentAll(this.AssignmentID);
      if (result && result["buffer"] != null && result["FileName"]) {
        // *this only downloads the doc
        // *the user will open their doc in their default Windows application
        const linkSource = result["buffer"];
        const downloadLink = document.createElement("a");
        downloadLink.href = linkSource;
        downloadLink.download = result["FileName"];
        downloadLink.click();

        this.progressBarService.stop();
      } else {
        this.progressBarService.stop();
        this.snackBarService.openSnackbar(
          "Failed to download all documents",
          SnackbarType.warning,
        );
      }

    } catch (error) {
      console.error(error);
      this.snackBarService.openSnackbar(
        "Failed to download all documents",
        SnackbarType.warning,
      );
      this.progressBarService.stop();
    }
  }

  ngOnDestroy() {
    if (this.docsSubject) {
      this.ticketDocumentService.addToDocs(null)
      this.docsSubject.unsubscribe();
    }
    if (this.heldDownSubscription) {
      this.ticketDocumentService.setIsHeldDown(false)
      this.heldDownSubscription.unsubscribe();
    }
    this.destroy$.next();
    this.destroy$.complete();
    this.clearSelected()
  }
}